body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
    color: #333;
}
.container {
    text-align: center;
    max-width: 600px;
    padding: 20px;
}
h1 {
    font-size: 4rem;
    color: #ff6b6b;
    margin-bottom: 0;
}
p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}
.signature {
    font-size: 1rem;
    color: #666;
    margin-top: 30px;
}
.home-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.home-button:hover {
    background-color: #0056b3;
}
.illustration {
    margin: 20px 0;
}
.illustration img {
    max-width: 100%;
    height: auto;
}